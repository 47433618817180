import {StyleSheet, Font} from "@react-pdf/renderer";

import Regular from "../../../assets/fonts/Montserrat/Montserrat-Regular.ttf";
import BlackItalic from "../../../assets/fonts/Montserrat/Montserrat-BlackItalic.ttf";
import BoldItalic from "../../../assets/fonts/Montserrat/Montserrat-BoldItalic.ttf";
import ExtraBold from "../../../assets/fonts/Montserrat/Montserrat-ExtraBold.ttf";
import ExtraLight from "../../../assets/fonts/Montserrat/Montserrat-ExtraLight.ttf";
import Italic from "../../../assets/fonts/Montserrat/Montserrat-Italic.ttf";
import Light from "../../../assets/fonts/Montserrat/Montserrat-Light.ttf";
import Medium from "../../../assets/fonts/Montserrat/Montserrat-Medium.ttf";
import SemiBold from "../../../assets/fonts/Montserrat/Montserrat-SemiBold.ttf";
import Thin from "../../../assets/fonts/Montserrat/Montserrat-Thin.ttf";

Font.register({
    family: "Montserrat",
    fonts: [
        {src: Regular, fontWeight: "400"},
        {src: BlackItalic, fontWeight: "900", fontStyle: "italic"},
        {src: BoldItalic, fontWeight: "700", fontStyle: "italic"},
        {src: ExtraBold, fontWeight: "800"},
        {src: ExtraLight, fontWeight: "200"},
        {src: Italic, fontWeight: "400", fontStyle: "italic"},
        {src: Light, fontWeight: "300"},
        {src: Medium, fontWeight: "500"},
        {src: SemiBold, fontWeight: "600"},
        {src: Thin, fontWeight: "100"},
    ],
});


export const styles = StyleSheet.create({
    page: {
        padding: 20,
        paddingVertical: 30,
        backgroundColor: "#FFFFFF",
        fontFamily: "Montserrat",
        fontWeight: "400",
        fontSize: 16,
        alignItems: 'center'
    },

    sectionHeader: {
        marginBottom: 10,
        padding: 15,
        width: '98%',
        borderWidth: 1,
        borderColor: '#000',
        borderRadius: 16,
        gap: 5
    },

    section: {
        marginBottom: 10,
        padding: 15,
        width: '90%',
        height: 500,
        borderWidth: 1,
        borderColor: '#000',
        borderRadius: 16,
    },

    sectionInfo: {
        marginBottom: 10,
        padding: 15,
        width: '98%',
        borderWidth: 1,
        borderColor: '#000',
        borderRadius: 16,
        gap:10
    },

    header: {
        fontSize: 12,
        fontWeight: 'bold',
        color: "black"
    },

    headerText: {
        fontSize: 13,
        fontWeight: 'bold',
        color: "black"
    },
    table: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#000",
        marginBottom: 10,
    },
    tableRow: {
        flexDirection: "row",
        width: '100%'
    },
    tableCol: {
        width: "25%",
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#000",
        padding: 5,
    },

    tableColSmall: {
        width: "15%",
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#000",
        padding: 5,
        alignItems: "center"
    },


    tableColSpan: {
        flexDirection: "row",
        gap: 20,
        alignItems: "center",
        justifyContent: "space-between"
    },

    tableColBig: {
        width: "35%",
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#000",
        padding: 5,
    },
    tableCell: {
        fontSize: 10,
    },

    textBlock: {
        flexDirection: "row",
        gap: 5
    }

})
