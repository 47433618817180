import React, {useEffect, useState} from 'react';
import axios from "axios";
import { API } from '../../helpers/const';
import {Button, Modal} from "react-bootstrap";
import GeneratePdf from "../Admin/GeneratePdf";

const InfoDogovor = () => {

    const [data, setData] = useState([]);

    useEffect(()=> {
        getFilterData()
    }, [] )
    const getFilterData = async () => {
        try {
            const responce = await axios(`${API}api/contest/contestFilter/3`)

            if(responce.status === 200) {
                console.log(responce.data.result.data)
               setData(responce.data.result)
            }else{
                alert('произошла ошибка')
            }
        }catch (error) {
            console.log(error)
        }
    }

    console.log(data, 'data')

    const [showPdfModal, setShowPdfModal] = useState(false);
    const [selectedContestId, setSelectedContestId] = useState(null);

    const downloadOpenProtacol = (codeid) => {
        setSelectedContestId(codeid)
        setShowPdfModal(true);
    };

    return (
        <div className="container-fluid d-flex justify-content-center align-items-center" style={{marginTop: "2vh"}}>
            <div className="card w-100" style={{border: "none"}}>
                <div className="card-body">

                    <div className="container_information_client" style={{maxHeight: "600px", overflowY: "auto"}}>
                        <table class="table w-100">
                            <thead>
                            <tr>
                                <th>№</th>
                                <th>Номер</th>
                                <th>Предмет закупки</th>
                                <th>Формат закупа</th>
                                <th>Способ закупа</th>
                                <th>Тип закупа</th>
                                <th>Планируемая сумма</th>
                                <th>Cумма победителя</th>
                                <th>Победитель</th>
                                <th>Действие</th>
                            </tr>
                            </thead>
                            <tbody>
                            {data.winners?.map((item, index) => (
                                <tr key={index}>
                                    <td>{index+1}</td>
                                    <td>{item.codeid + item.year + index + 1}</td>
                                    <td>{item.contest_description}</td>
                                    <td>{item.format_purchase}</td>
                                    <td>{item.method_purchase}</td>
                                    <td>{item.type_purchase}</td>
                                    <td>{item.planned_summ} Сом</td>
                                    <td>{item.contract_summ} Сом</td>
                                    <td>{item.name_organization}</td>
                                    <td>
                                        <div>
                                            <Button
                                                variant="primary"
                                                size="sm"
                                                onClick={() => downloadOpenProtacol(item.codeid)}
                                                style={{gap: 10, display: "flex", fontSize: 15}}
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                                     fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
                                                    <path
                                                        d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
                                                    <path
                                                        d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>
                                                </svg>
                                                <i style={{fontSize: 15}}>
                                                    Скачать протокол вскрытия
                                                </i>
                                            </Button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <Modal
                show={showPdfModal}
                onHide={() => setShowPdfModal(false)}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Протокол вскрытие конкурса</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <GeneratePdf   contestId={selectedContestId}/>
                </Modal.Body>
            </Modal>

        </div>
    );
}


export default InfoDogovor;
