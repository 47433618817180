import {UseRegister} from "../../Context/ContextProviderRegister";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {API} from "../../helpers/const";
import {useNavigate} from "react-router-dom";
import Sidebar from "./Sidebar";
import {Power} from "phosphor-react";
import {Button, Form, ListGroup, Modal} from "react-bootstrap";
import './Directories.css'
import EditDirectories from "../editDirectories/editDirectories";

const Directories = () => {
    const [userEmail, setUserEmail] = useState('');
    const [spPurchase,setSpPurchase ] = useState(
        {
            format: [],
            method: [],
            type: [],
            typeOrganization: []
        })

    const [directoryData, setDirectoryData] = useState({})
    const [showEditModal, setShowEditModal] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [activeDirectory, setActiveDirectory]  = useState(0)
    const [showDeleteModal, setShowDeleteModal] = useState(false)

    useEffect(() => {
        const userDataString = localStorage.getItem('userEmail');
        if (userDataString) {
            setUserEmail(userDataString);
        }
    }, []);
    const getSpPurchase = async () => {
        try {
            const { data } = await axios.get(`${API}api/contest/getSpPurchase`);
            setSpPurchase(data.result.data);
        } catch (error) {
            console.error('Ошибка при получении типов закупок:', error.message);
        }
    };

    useEffect(() => {
        getSpPurchase();
    }, []);


    const { format, method, type, typeOrganization } = spPurchase;
    const navigate = useNavigate()
    const signout = () => {
        const confirmed = window.confirm("Вы уверены, что хотите выйти из аккаунта?");
        if (confirmed) {
            localStorage.removeItem('authToken');
            localStorage.removeItem('userEmail');
            localStorage.removeItem('codeid');
            localStorage.removeItem('userInfo');
            localStorage.removeItem('role');
            console.log('User signed out');
            navigate('/');
        }
    };


    const handleDerectoryClick = (codeid) => {
        setActiveDirectory(codeid)
    }

    const addDirectories = (number) => {
        setActiveDirectory(number)
        setShowCreateModal(true)
    };

    const handleCloseCreate = () => {
        setShowCreateModal(false);
        getSpPurchase()
    };

    const handleCloseDelete = () => {
        setShowDeleteModal(false);
        getSpPurchase()
    };

    const handleCloseEdit = () => {
        setShowEditModal(false);
        setDirectoryData({})
        getSpPurchase()
    };
    const handleUpdateDirecory = (item) => {
        setShowEditModal(true);
        setDirectoryData(item)
    };

    function handleDeleteDirectory(data) {
        setDirectoryData(data)
        setShowDeleteModal(true)
    }

    return (
        <div className="oll_sistem">
            <Sidebar />
            <div className="navbar_container">
                <div style={{
                    background: 'white',
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    padding: "0.6vw",
                    overflowX: "auto",
                    maxWidth: "100%",
                }}>
                    <div style={{
                        display: "flex",
                        textAlign: "center",
                        gap: '10px',
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <div>{userEmail}</div>
                        <button
                            onClick={signout}
                            className="btn"
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                background: 'transparent'
                            }}
                        >
                            <Power size={30} color="red" />
                        </button>

                    </div>
                </div>

                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="card">
                        <div className="flex-container">
                            <div className="list-group-container-group">
                                <ListGroup style={{borderRight: '1px solid gray', marginBottom: "1vh"}}>
                                    <ListGroup.Item disabled>Справочники</ListGroup.Item>
                                    <ListGroup.Item active={activeDirectory === 1}
                                                    onClick={() => handleDerectoryClick(1)}>
                                        Метод закупа
                                    </ListGroup.Item>
                                    <ListGroup.Item active={activeDirectory === 2}
                                                    onClick={() => handleDerectoryClick(2)}>
                                        Тип закупа
                                    </ListGroup.Item>
                                    <ListGroup.Item active={activeDirectory === 3}
                                                    onClick={() => handleDerectoryClick(3)}>
                                        Формат закупа
                                    </ListGroup.Item>

                                    <ListGroup.Item active={activeDirectory === 4}
                                                    onClick={() => handleDerectoryClick(4)}>
                                        Форма собственности
                                    </ListGroup.Item>

                                </ListGroup>
                            </div>

                            {activeDirectory === 1 && <div className="list-group-container">
                                <ListGroup style={{borderRight: '1px solid gray', marginBottom: "1vh", height: '100%'}}>
                                    <ListGroup.Item>
                                        <div className='action_save'>
                                            <div className='action-start'>
                                                <p>Метод закупа</p>
                                            </div>
                                            <div className='action-center'>
                                                <Button variant="success" onClick={()=> addDirectories(1)}>Добавить
                                                    справочник</Button>
                                            </div>
                                        </div>
                                    </ListGroup.Item>
                                    <div className="table-responsive">
                                        <table className="table table-striped">
                                            <thead>
                                            <tr>
                                                <th>№</th>
                                                <th>Наименование</th>
                                                <th>Действие</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {method && method.length !== 0 && method.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.method_purchase}</td>
                                                    <td>
                                                        <div className="btn-groups">
                                                            <button type="button"
                                                                    className="btn btn-warning"
                                                                    onClick={()=> handleUpdateDirecory(item)}
                                                            >Редактировать
                                                            </button>
                                                            <button
                                                                type='button'
                                                                className='btn btn-danger'
                                                                onClick={()=> handleDeleteDirectory(item)}
                                                            >Удалить
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </ListGroup>
                            </div>}
                            {activeDirectory === 2 && <div className="list-group-container">
                                <ListGroup style={{borderRight: '1px solid gray', marginBottom: "1vh", height: '100%'}}>
                                    <ListGroup.Item>
                                        <div className='action_save'>
                                            <div className='action-start'>
                                                <p>Тип закупа</p>
                                            </div>
                                            <div className='action-center'>
                                                <Button variant="success" onClick={()=> addDirectories(2)}>Добавить
                                                    справочник</Button>
                                            </div>
                                        </div>
                                    </ListGroup.Item>
                                    <div className="table-responsive">
                                        <table className="table table-striped">
                                            <thead>
                                            <tr>
                                                <th>№</th>
                                                <th>Наименование</th>
                                                <th>Действие</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {type && type.length !== 0 && type.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.type_purchase}</td>
                                                    <td>
                                                        <div className="btn-groups">
                                                            <button type="button"
                                                                    className="btn btn-warning"
                                                                    onClick={()=> handleUpdateDirecory(item)}
                                                            >Редактировать
                                                            </button>
                                                           <button
                                                                type='button'
                                                                className='btn btn-danger'
                                                                onClick={()=> handleDeleteDirectory(item)}
                                                            >Удалить
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </ListGroup>
                            </div>}
                            {activeDirectory === 3 && <div className="list-group-container">
                                <ListGroup style={{borderRight: '1px solid gray', marginBottom: "1vh", height: '100%'}}>
                                    <ListGroup.Item>
                                        <div className='action_save'>
                                            <div className='action-start'>
                                                <p>Формат закупа</p>
                                            </div>
                                            <div className='action-center'>
                                                <Button variant="success" onClick={()=> addDirectories(3)}>Добавить
                                                    справочник</Button>
                                            </div>
                                        </div>
                                    </ListGroup.Item>
                                    <div className="table-responsive">
                                        <table className="table table-striped">
                                            <thead>
                                            <tr>
                                                <th>№</th>
                                                <th>Наименование</th>
                                                <th>Действие</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {format && format.length !== 0 && format.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.format_purchase}</td>
                                                    <td>
                                                        <div className="btn-groups">
                                                            <button type="button"
                                                                    className="btn btn-warning"
                                                                    onClick={()=> handleUpdateDirecory(item)}
                                                            >Редактировать
                                                            </button>
                                                           <button
                                                                type='button'
                                                                className='btn btn-danger'
                                                                onClick={()=> handleDeleteDirectory(item)}
                                                            >Удалить
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </ListGroup>
                            </div>}
                            {activeDirectory === 4 && <div className="list-group-container">
                                <ListGroup style={{borderRight: '1px solid gray', marginBottom: "1vh", height: '100%'}}>
                                    <ListGroup.Item>
                                        <div className='action_save'>
                                            <div className='action-start'>
                                                <p>Форма собственности</p>
                                            </div>
                                            <div className='action-center'>
                                                <Button variant="success" onClick={()=> addDirectories(4)}>Добавить
                                                    справочник</Button>
                                            </div>
                                        </div>
                                    </ListGroup.Item>
                                    <div className="table-responsive">
                                        <table className="table table-striped">
                                            <thead>
                                            <tr>
                                                <th>№</th>
                                                <th>Наименование</th>
                                                <th>Действие</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {typeOrganization && typeOrganization.length !== 0 && typeOrganization.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.name}</td>
                                                    <td>
                                                        <div className="btn-groups">
                                                            <button type="button"
                                                                    className="btn btn-warning"
                                                                    onClick={()=> handleUpdateDirecory(item)}
                                                            >Редактировать
                                                            </button>
                                                           <button
                                                                type='button'
                                                                className='btn btn-danger'
                                                                onClick={()=> handleDeleteDirectory(item)}
                                                            >Удалить
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </ListGroup>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>

            {showEditModal && (
                <EditDirectories
                    type={activeDirectory}
                    data={directoryData}
                    action="1"
                    editBaseUser={showEditModal}
                    handleCloseEdit={handleCloseEdit}
                />
            )}

            {showCreateModal && (
                <EditDirectories
                    type={activeDirectory}
                    action="2"
                    editBaseUser={showCreateModal}
                    handleCloseEdit={handleCloseCreate}
                />
            )}

            {showDeleteModal && (
                <EditDirectories
                    type={activeDirectory}
                    action="3"
                    data={directoryData}
                    editBaseUser={showDeleteModal}
                    handleCloseEdit={handleCloseDelete}
                />
            )}
        </div>
    );
}

export default Directories
