import {Document, Page, Text} from "@react-pdf/renderer";
import {View, PDFViewer} from "@react-pdf/renderer";
import {styles} from "./styles/style";
import {useEffect, useState} from "react";
import axios from "axios";
import {API} from "../../helpers/const";


const GeneratePdf = ({contestId }) => {
    console.log(contestId, 'contestId')
    const [isAdmin, setIsAdmin] = useState(false);
    const [contestDetails, setContestDetails] = useState(null);
    const [contestWinners, setContestWinners] = useState(null);
    const [detailUsers, setDetailUsers] = useState([]);

    useEffect(() => {
        const userRole = localStorage.getItem('role');
        setIsAdmin(userRole === 'Администратор');

        getContestDetails();
        getOrdersByContest();
    }, [contestId]);

    console.log(contestId, 'contestId')
    const getContestDetails = async () => {
        try {
            const response = await axios.get(`${API}api/contest/getContestDetails/${contestId}`);
            if (response.status === 200) {
                const result = response.data.result.data;
                setContestDetails(result[0]);
            } else {
                console.log(response)
            }
        } catch (error) {
            console.log(error)
        }
    };

    const getOrdersByContest = async () => {
        try {
            const response = await axios.get(`${API}api/orders/getOrderDetails/${contestId}`);
            console.log(response)
            if (response.status === 200) {
                const result = response.data.result.data;
                setContestWinners(response?.data?.result?.winners[0])
                setDetailUsers(result);
            } else {
                alert('Данные не найдены');
            }
        } catch (error) {
            console.log(error)
        }
    };


    console.log(contestDetails, 'contestDetails')
    console.log(detailUsers, 'contestWinners')
    return (
        <PDFViewer style={{width: '100%', height: '600px'}}>
            <Document>
                <Page size="A4" style={styles.page} orientation="landscape">
                    <View style={styles.sectionHeader}>
                        <Text style={styles.header}>Дата: {contestDetails?.start_date}</Text>
                        <View style={styles.textBlock}>
                            <Text style={styles.headerText}>Номер:</Text>
                            <Text style={styles.header}>{contestDetails?.codeid + contestDetails?.year + 1}</Text>
                        </View>

                    </View>


                    <View style={styles.sectionInfo}>
                        <Text style={{...styles.header, color: 'black'}}>Конкурсная информация:</Text>
                        <Text style={styles.header}>Сумма: {contestDetails?.planned_summ}</Text>

                        <View style={styles.textBlock}>
                            <Text style={styles.headerText}>Наименование:</Text>
                            <Text style={styles.header}>{contestDetails?.contest_description}</Text>
                        </View>

                        <View style={styles.textBlock}>
                            <Text style={styles.headerText}>Закупающая организация:</Text>
                            <Text style={styles.header}>{contestDetails?.contest_name}</Text>
                        </View>

                    </View>


                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <View style={{...styles.tableCol, width: '25'}}>
                                <Text style={styles.tableCell}>№</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Поставщик</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>ИНН</Text>
                            </View>
                            <View style={styles.tableColSmall}>
                                <Text style={styles.tableCell}>Валюта тендерной заявки</Text>
                            </View>

                            <View style={styles.tableColBig}>
                                <Text style={styles.tableCell}>Гарантийное обеспечение тендеров</Text>

                                <View style={styles.tableColSpan}>
                                    <View style={{...styles.tableColSmall, width: '50%'}}>
                                        <Text style={styles.tableCell}>Размер</Text>
                                    </View>
                                    <View style={{...styles.tableColSmall, width: '50%'}}>
                                        <Text style={styles.tableCell}>Форма</Text>
                                    </View>
                                </View>
                            </View>
                        </View>

                        {detailUsers.map((item, index ) => (
                            <View key={index} style={styles.tableRow}>
                                <View style={{...styles.tableCol, width: '25'}}>
                                    <Text style={styles.tableCell}>{index + 1}</Text>
                                </View>

                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{item?.finalUser?.user?.name_organization}</Text>
                                </View>

                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{item?.finalUser?.user?.inn}</Text>
                                </View>

                                <View style={styles.tableColSmall}>
                                    <Text style={styles.tableCell}>Кыргызский сом</Text>
                                </View>

                                <View style={styles.tableColBig}>
                                    <View style={styles.tableColSpan}>
                                        <View style={{...styles.tableColSmall, width: '50%'}}>
                                            <Text style={styles.tableCell}>{item?.summ}</Text>
                                        </View>
                                        <View style={{...styles.tableColSmall, width: '50%'}}>
                                            <Text style={styles.tableCell}>Банковская гарантия</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        ))}
                    </View>


                </Page>
            </Document>
        </PDFViewer>
    );
};

export default GeneratePdf;
