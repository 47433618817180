import {Form, Modal} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {API} from "../../helpers/const";
import {useToast} from "../../Context/ToastContext";

const EditDirectories = ({type, data, action, editBaseUser, handleCloseEdit}) => {
    const [name, setName] = useState('');
    const [label, setLabel] = useState('');
    const [actionLabel, setActionLabel] = useState('');
    const [objectCodeid, setObjectCodeid] = useState(0);
    const {successToast, errorToast} = useToast();
    const [isDelete, setIsDelete] = useState(false)


    useEffect(() => {
        if (data) {
            console.log(data)
            setObjectCodeid(data.codeid);
            switch (type) {
                case 1:
                    setName(data.method_purchase);
                    setLabel('Метод закупа');
                    break;
                case 2:
                    setName(data.type_purchase);
                    setLabel('Тип закупа');
                    break;
                case 3:
                    setName(data.format_purchase);
                    setLabel('Формат закупа');
                    break;
                case 4:
                    setName(data.name);
                    setLabel('Форма собственности');
                    break;
                default:
                    break;
            }
        }
        switch (type) {
            case 1:
                setLabel('Метод закупа');
                break;
            case 2:
                setLabel('Тип закупа');
                break;
            case 3:
                setLabel('Формат закупа');
                break;
            case 4:
                setLabel('Форма собственности');
                break;
            default:
                break;
        }
        switch (action) {
            case '1':
                setActionLabel('Редактирование');
                break;
            case '2':
                setActionLabel('Добавление');
                break;
            case '3':
                setIsDelete(true)
                break;
            default:
                break;
        }
    }, [type, data, action]);

    const handleChange = (event) => {
        setName(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const requestData = {
            name: name,
            codeid: objectCodeid,
            action: action,
            type: type
        };
        let actionResult = action === 1 ? 'добавлен' : 'обновлен';

        try {
            const response = await axios.post(`${API}api/contest/updateDirectories`, requestData);
            if (response.status === 200) {
                successToast('Успех', `Справочник успешно ${actionResult}`);
                handleCloseEdit()
            }
        } catch (error) {
            console.log(error);
            errorToast('Ошибка', 'Ошибка при выполнении запроса');
            handleCloseEdit()
        }
    };

    return (
        <div>
            {!isDelete && (
                <Modal backdrop="static" show={editBaseUser} onHide={handleCloseEdit} className="custom-modal modalact"
                       style={{marginTop: "1vw", width: "100%"}}>
                    <Modal.Header closeButton>
                        <Modal.Title style={{fontSize: "18px"}}>{actionLabel}: {label}</Modal.Title>
                    </Modal.Header>
                    <Form style={{padding: '1vw'}} onSubmit={handleSubmit}>
                        <div className="card-body">
                            <div className="row g-3">
                                <div className="col">
                                    <label className="form-label">Название</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder='Введите название'
                                        value={name}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div style={{
                                margin: '0 auto',
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: 20
                            }}>
                                <button type="submit" className="btn btn-success">
                                    Сохранить
                                </button>
                            </div>
                        </div>
                    </Form>
                </Modal>
            )}

            {isDelete && (
                <Modal backdrop="static" show={editBaseUser} onHide={handleCloseEdit} className="custom-modal modalact"
                       style={{marginTop: "1vw", width: "100%"}}>
                    <Modal.Header closeButton>
                        <Modal.Title style={{fontSize: "18px"}}>Вы точно хотите удалить справочник</Modal.Title>
                    </Modal.Header>
                    <Form style={{padding: '1vw'}} onSubmit={handleSubmit}>
                        <div className="card-body">
                            <div className="row g-3">
                                <div className="col"
                                     style={{display: "flex", alignItems: "center", justifyContent: "center", gap: 10}}>
                                    <p>Название:</p> <p>{name}</p>
                                </div>
                            </div>
                            <div style={{
                                margin: '0 auto',
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: 20
                            }}>
                                <button type="submit" className="btn btn-danger">
                                    Подтвердить
                                </button>
                            </div>
                        </div>
                    </Form>
                </Modal>
            )}
        </div>
    );
}

export default EditDirectories;
