import React from 'react'
import Page from './components/Page/Page'
import MainRoutes from "./Routes/MainRoutes";

const App = () => {
    return (
        <Page/>
    )
}

export default App
