import React from 'react';
import { Card } from 'react-bootstrap';

const ContactsAndDetails = () => {
    return (
        <div className="container-fluid d-flex justify-content-center align-items-center" style={{ marginTop: "2vh" }}>
            <Card className="bg-light border-0 rounded-3 p-3" style={{ backgroundColor: "rgba(98, 98, 98, 1)", width: "90%", marginTop: "5vw" }}>
                <Card.Body>
                    <div className="row row-cols-1 row-cols-md-3 g-4">
                        <div className="col">
                            <Card className="bg-white border-0 rounded-3 p-3 h-100">
                                <Card.Title className="text-primary mb-3" style={{ fontSize: "20px", fontWeight: "bold" }}>Контакты</Card.Title>
                                <div style={{ fontSize: "16px" }}>
                                    <p><strong>Начальник:</strong> Мамбетказиев Мирбек</p>
                                    <div style={{ display: "flex", alignItems: "center", gap: "1vw" }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#0D6EFD" className="bi bi-telephone" viewBox="0 0 16 16">
                                            <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                                        </svg>
                                        <p style={{ margin: 0 }}>Рабочий телефон: +996 312 54 52 15</p>
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center", gap: "1vw", }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#0D6EFD" class="bi bi-envelope-at" viewBox="0 0 16 16">
                                            <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2zm3.708 6.208L1 11.105V5.383zM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2z" />
                                            <path d="M14.247 14.269c1.01 0 1.587-.857 1.587-2.025v-.21C15.834 10.43 14.64 9 12.52 9h-.035C10.42 9 9 10.36 9 12.432v.214C9 14.82 10.438 16 12.358 16h.044c.594 0 1.018-.074 1.237-.175v-.73c-.245.11-.673.18-1.18.18h-.044c-1.334 0-2.571-.788-2.571-2.655v-.157c0-1.657 1.058-2.724 2.64-2.724h.04c1.535 0 2.484 1.05 2.484 2.326v.118c0 .975-.324 1.39-.639 1.39-.232 0-.41-.148-.41-.42v-2.19h-.906v.569h-.03c-.084-.298-.368-.63-.954-.63-.778 0-1.259.555-1.259 1.4v.528c0 .892.49 1.434 1.26 1.434.471 0 .896-.227 1.014-.643h.043c.118.42.617.648 1.12.648m-2.453-1.588v-.227c0-.546.227-.791.573-.791.297 0 .572.192.572.708v.367c0 .573-.253.744-.564.744-.354 0-.581-.215-.581-.8Z" />
                                        </svg>
                                        <p style={{ margin: 0 }}>E-mail:</p>
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center", gap: "1vw", }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#0D6EFD" class="bi bi-house-door" viewBox="0 0 16 16">
                                            <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4z" />
                                        </svg>
                                        <p style={{ margin: 0 }}>Адрес:</p>
                                    </div>
                                </div>
                            </Card>
                        </div>
                        <div className="col">
                            <Card className="bg-white border-0 rounded-3 p-3 h-100">
                                <Card.Title className="text-primary mb-3" style={{ fontSize: "20px", fontWeight: "bold" }}>Специалист</Card.Title>
                                <div style={{ fontSize: "16px" }}>
                                    <p><strong>Специалист:</strong> Урустомбекова Айнура</p>
                                    <div style={{ display: "flex", alignItems: "center", gap: "1vw" }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#0D6EFD" className="bi bi-telephone" viewBox="0 0 16 16">
                                            <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                                        </svg>
                                        <p style={{ margin: 0 }}>Рабочий телефон: +996 312 54 52 15</p>
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center", gap: "1vw", }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#0D6EFD" class="bi bi-envelope-at" viewBox="0 0 16 16">
                                            <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2zm3.708 6.208L1 11.105V5.383zM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2z" />
                                            <path d="M14.247 14.269c1.01 0 1.587-.857 1.587-2.025v-.21C15.834 10.43 14.64 9 12.52 9h-.035C10.42 9 9 10.36 9 12.432v.214C9 14.82 10.438 16 12.358 16h.044c.594 0 1.018-.074 1.237-.175v-.73c-.245.11-.673.18-1.18.18h-.044c-1.334 0-2.571-.788-2.571-2.655v-.157c0-1.657 1.058-2.724 2.64-2.724h.04c1.535 0 2.484 1.05 2.484 2.326v.118c0 .975-.324 1.39-.639 1.39-.232 0-.41-.148-.41-.42v-2.19h-.906v.569h-.03c-.084-.298-.368-.63-.954-.63-.778 0-1.259.555-1.259 1.4v.528c0 .892.49 1.434 1.26 1.434.471 0 .896-.227 1.014-.643h.043c.118.42.617.648 1.12.648m-2.453-1.588v-.227c0-.546.227-.791.573-.791.297 0 .572.192.572.708v.367c0 .573-.253.744-.564.744-.354 0-.581-.215-.581-.8Z" />
                                        </svg>
                                        <p style={{ margin: 0 }}>E-mail:</p>
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center", gap: "1vw", }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#0D6EFD" class="bi bi-house-door" viewBox="0 0 16 16">
                                            <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4z" />
                                        </svg>
                                        <p style={{ margin: 0 }}>Адрес:</p>
                                    </div>

                                </div>
                            </Card>
                        </div>
                        <div className="col">
                            <Card className="bg-white border-0 rounded-3 p-3 h-100">
                                <Card.Title className="text-primary mb-3" style={{ fontSize: "20px", fontWeight: "bold" }}>Реквизиты</Card.Title>
                                <div style={{ fontSize: "16px" }}>
                                    <div style={{ display: "flex", alignItems: "center", gap: "1vw", }}>
                                        <i class="bi bi-bank" style={{ color: "#0D6EFD" }}></i>
                                        <p style={{ margin: 0 }}>ОАО "РСК Банк"</p>
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center", gap: "1vw", }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#0D6EFD" class="bi bi-123" viewBox="0 0 16 16">
                                            <path d="M2.873 11.297V4.142H1.699L0 5.379v1.137l1.64-1.18h.06v5.961zm3.213-5.09v-.063c0-.618.44-1.169 1.196-1.169.676 0 1.174.44 1.174 1.106 0 .624-.42 1.101-.807 1.526L4.99 10.553v.744h4.78v-.99H6.643v-.069L8.41 8.252c.65-.724 1.237-1.332 1.237-2.27C9.646 4.849 8.723 4 7.308 4c-1.573 0-2.36 1.064-2.36 2.15v.057zm6.559 1.883h.786c.823 0 1.374.481 1.379 1.179.01.707-.55 1.216-1.421 1.21-.77-.005-1.326-.419-1.379-.953h-1.095c.042 1.053.938 1.918 2.464 1.918 1.478 0 2.642-.839 2.62-2.144-.02-1.143-.922-1.651-1.551-1.714v-.063c.535-.09 1.347-.66 1.326-1.678-.026-1.053-.933-1.855-2.359-1.845-1.5.005-2.317.88-2.348 1.898h1.116c.032-.498.498-.944 1.206-.944.703 0 1.206.435 1.206 1.07.005.64-.504 1.106-1.2 1.106h-.75z" />
                                        </svg>
                                        <p style={{ margin: 0 }}>БИК 129001</p>
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center", gap: "1vw", }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#0D6EFD" class="bi bi-coin" viewBox="0 0 16 16">
                                            <path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518z" />
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                            <path d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11m0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12" />
                                        </svg>
                                        <p style={{ margin: 0 }}>р/счет 1299003230451037 (в сомах)</p>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
}

export default ContactsAndDetails;
